<template>
    <div class="sponsors">
        <div v-if="title" class="content">
            <h2 class="title">
                Trusted by Top Producing Agents<br> & Leading Brands Nationwide
            </h2>
        </div>
        <div class="logos" v-bind:class="{ padding: homepagePadding }">
            <div v-for="(logo, i) in filteredLogos" :key="i" class="img_container">
                <img :src="logo">
            </div>
        </div>
        <div class="splatter-bottom" v-if="splatter">
            <img src="/images/splatter-bottom.png" alt="splatter style">
        </div>
    </div>
</template>

<script>
export default {
    name:"Sponsors",
    props:{
        title:{
            type: Boolean,
            default: false
        },
        showFirstHalf:{
            type: Boolean,
            default: false
        },
        homepagePadding:{
            type: Boolean,
            default: false
        },
        splatter: {
            type: Boolean,
            default: false
        }
    },
    computed:{
        filteredLogos: function() {
            if(this.showFirstHalf){
                return this.logos
            }
            return [...this.logos, ...this.logos2];
        }
    },
    data(){
        return{
            logos: [
                '/images/logo/uptowncreative-1-comp.png',
                '/images/logo/uptowncreative-2-ar.png',
                '/images/logo/uptowncreative-3-lqr.png',
                '/images/logo/uptowncreative-4-rbc.png',
                '/images/logo/uptowncreative-5-sfjg.png',
                '/images/logo/uptowncreative-6-aaa.png',
            ],
            logos2: [
                '/images/logo/uptowncreative-7-hkl.png',
                '/images/logo/uptowncreative-8-oak.png',
                '/images/logo/uptowncreative-9-CR.png',
                '/images/logo/uptowncreative-10-slg.png',
                '/images/logo/uptowncreative-11-cph.png',
                '/images/logo/uptowncreative-12-cb.png',
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.sponsors{
    background: #f1f3f7;
    text-align: center;
}
.content{
    margin: 0 70px;
    margin-top: 87px;
    h2{
        margin: 0;
    }
}
.logos{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 70px;
    padding-top: 30px;
    &.padding{
        padding: 4rem 70px;
    }
    .img_container{
        width: 16.66%;
        padding: 42.75px 15px;
    }
    img{
        height: auto;
        max-height: 74px;
        max-width: 90%;
        display: block;
        margin: 0 auto;
    }
}

@media (max-width: $tablet) {
    .logos{
        padding: 0 70px;
        padding-top: 30px;
        &.padding{
            padding: 4rem 55px;
        }
    }
}
@media (max-width: $mobile) { 
    .content{
        margin: 0 2rem;
    }
    .logos{
        padding: 34px 2rem;
        &.padding{
            padding: 34px 1rem;
        }
       .img_container{
            width: 33.33%;
        }
        img{
            width: 90px;
            height: auto;
        }
    }
}
</style>