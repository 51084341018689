<template>
    <section class="homevideo">
        <div class="homevideo__container">
            <div class="homevideo__background">
                <div class="homevideo__background__video">
                    <figure class="homevideo__background__asset">
                        <video 
                            autoplay
                            class="video"
                            data-load="objectFit"
                            loop
                            muted
                            playsinline>
                            <source data-src="https://api.uptowncreative.io/uploads/_/assets/chadrogers/luxe-bg-video.mp4" type="video/mp4" src="https://api.uptowncreative.io/uploads/_/assets/chadrogers/luxe-bg-video.mp4">
                        </video>
                    </figure>
                </div>
                <div class="homevideo__background__mobile">
                    <figure class="homevideo__background__asset">
                        <img alt="Coastal Luxe"  src="/images/initial-slide.jpg">
                    </figure>
                </div>
            </div>
            <div class="homevideo__gradient"></div>
            <div class="homevideo__foreground">
                <div class="homevideo__content">
                    <h1>
                        Find Your Dream<br>
                        Make your Move
                    </h1>
                </div>
                <div class="scrolldown"><a href="#featured-listings" class="btn arrow bounce"></a></div>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
.homevideo{
    &__container{
        height: 100vh;
        width: 100%;
        min-height: 480px;
    }
    &__background{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        &__video{
            display:block;
            &.isloading{
                background: url("/images/initial-slide.jpg") cover;
            }
        }
        &__asset{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            .video{
                position: relative;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__mobile{
            display: none;
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }
    &__gradient{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to bottom, #000, #000);
        opacity: 0.35;
    }
    &__foreground{
        position: absolute;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: #fff;
    }
    p{
        font-weight: 600;
        font-size: 18px;
        letter-spacing: 2px;
        color: #fff;
        opacity: 1;
        text-transform: uppercase;
        margin: 0;
        margin-left: 4.25rem;
    }
    h1{
        font-size: 60px;
        color: #fff;
        margin: 0 auto;
        text-align: center;
        text-transform: uppercase;
    }
}
figure{
    padding: 0;
    margin: 0;
}
.arrow{
    bottom: 0;
    width: 39px;
    height: 24px;
    background: url(/images/dn-arrow.png) no-repeat 50%;
}
.bounce{
    animation: bounce 2s infinite;
}
@keyframes bounce {
    0% {
        transform: translateY(-45px);
        opacity: 0;
    }
    100%{
        transform: translateY(0);
        opacity: 1;
    }
}

.scrolldown{
    width: 100%;
    position: absolute;
    bottom: 10px;
    text-align: center;
    border: none;
}
.btn{
    position: relative;
    z-index: 1;
    display: inline-block;
    margin: 10px 0;
    padding: 0 15px;
    height: 42px;
    outline: none;
    border: 2px solid #fff;
    color: #fff;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 40px;
    transition: color .3s;
    border: none;
}

@media (max-width: $tablet) {
    .homevideo{
        &__foreground{
            h1{
                line-height: 1;
                font-size: 45px;
            }
        }
    }
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), not all, only screen and (min-resolution: 192dpi){
    .arrow{
        background-image: url(/images/dn-arrow-2x.png);
        background-size: contain;
    }
}
</style>

<script>
export default {
    name:"HomeVideo",
    data(){
        return{
            isloading: true,
            title: "It's always a beautiful day in the neighborhood",
        }
    }
}
</script>