<template>
    <div id="home">
        <HomeVideo />
        <Sponsors :showFirstHalf="true" :splatter="true" v-if="false"/>
        <div v-if="loading">
            <Spinner size="large" message="Loading..." line-fg-color="#000" style="padding: 5rem 0;"/>
        </div>
        <div id="featured-listings" class="featured-properties" v-else>
            <div class="page-title">
                <h2>
                    Current Listings
                </h2>
            </div>
            <FeaturedProperties v-if="availableProperties.length > 0" align="right" title="Exclusive Listings" :data="availableProperties"/>
        </div>
        <div class="splatter-top">
            <img src="/images/splatter-top.png" alt="splatter style">
        </div>
        <div id="ryan" class="biography">
            <div class="biography-content">
                <div class="title">
                    <div class="title-section">
                        <h2 data-aos="fade-up"  data-aos-duration="1000" data-aos-anchor-placement="top-bottom">
                            Ryan<br>
                            Sokolowski
                        </h2>
                    </div>
                    <h6 data-aos="fade-up"  data-aos-duration="1000" data-aos-anchor-placement="top-bottom">
                        A Real Estate Expert<br>
                        Specializing in Luxury Coastal Properties
                    </h6>
                </div>
                <hr data-aos="fade-up"  data-aos-duration="1000" data-aos-anchor-placement="top-bottom">
                <div data-aos="fade-up"  data-aos-duration="1000" data-aos-anchor-placement="top-bottom" class="content">
                    <p>
                       For more than a decade, Ryan Sokolowski has been impressing real estate clients with his diligence, enthusiasm and seemingly effortless ability to make the impossible possible.
                    </p>
                    <router-link to="/team" class="btn">
                        More
                    </router-link>
                </div>
            </div>
            <img data-aos="fade-up"  data-aos-duration="1000" data-aos-anchor-placement="top-bottom" src="/images/ryan1.jpg" alt="Ryan Sokolowski">
        </div>
        <div class="splatter-bottom">
            <img src="/images/splatter-bottom.png" alt="splatter style">
        </div>
        <div class="sales">
            <img data-aos="fade-right"  data-aos-duration="1000" data-aos-anchor-placement="top-bottom" src="/images/ryanteam.jpg" alt="Ryan Sokolowski">
            <div class="sales-content">
                <div class="title">
                    <div class="title-section">
                        <h2 data-aos="fade-up"  data-aos-duration="1000" data-aos-anchor-placement="top-bottom">
                            Your Coastal<br>
                            Luxe Team
                        </h2>
                    </div>
                    <h6 v-if="false">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla blandit commodo leo.
                    </h6>
                </div>
                <hr>
                <div class="content" data-aos="fade-up"  data-aos-duration="1000" data-aos-anchor-placement="top-bottom">
                    <h5>
                        Top Sales
                    </h5>
                    <div v-for="(item, i) in sales" class="sales-list" :key="i">
                        <p>
                            <span class="price"> {{item.price | priceFormat}} </span> / {{item.address}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
import HomeVideo from '../components/HomeVideo'
import FeaturedProperties from "../components/FeaturedProperties"
import Spinner from 'vue-simple-spinner'
import Sponsors from '../components/Sponsors';

export default {
    name: "Home",
    components: {
        HomeVideo,
        FeaturedProperties,
        Spinner,
        Sponsors
    },
    metaInfo: {
        title: 'Coastal Luxe',
        meta: [{
            name: 'description', 
            content: "Coastal Luxe"
        }]
    },
    data(){
        return{
            loading: true,
            sales: [
                {
                    price: 4295000,
                    address: '445 N Fuller Ave'
                },
                {
                    price: 3795000,
                    address: '5041 Noeline Ave'
                },
                {
                    price: 3700000 ,
                    address: '8123 Zitola Terrace'
                },
                {
                    price: 3699000,
                    address: '13700 Marina Pointe Dr #1901'
                },
                {
                    price: 3290000,
                    address: '2525 Juanita WAY'
                },
                {
                    price: 2999999,
                    address: '7564 Stewart Ave'
                },
                {
                    price: 2899999,
                    address: '1390 Morningside Way'
                },
                {
                    price: 2895000,
                    address: '13700 Marina Pointe Dr #1920'
                },
                {
                    price: 2495000,
                    address: '13700 Marina Pointe Dr #1605'
                },
                {
                    price: 2400000,
                    address: '13700 Marina Pointe Dr #1505'
                },
                
            ]
        }
    },
    mounted:function(){
        this.axios.get('https://strapi.uptowncreative.io/coastal-luxe-properties?_sort=published_at:DESC')
        .then((response) => {
            this.data = response.data;
            this.loading = false;
        })
    },
    computed: {
        soldProperties:function(){
            var temp = this.data.filter((item) => {
                return item.Tag === 'sold' || item.Tag ==='past';
            }).sort((a, b) => {
               return b.Price - a.Price;
            })
            return temp;
        },
        availableProperties: function(){
            return this.data.filter((item) => {
                return item.Status === 'available' || item.Status === 'lease';
            })
        }
    },
}   
</script>

<style lang="scss" scoped>
.title{
    h6{
        margin: 1.5rem 0;
    }
}
.featured-properties{
    margin: 4rem auto;
}
.biography{
    background: #f1f3f7;
    padding: 6rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        display:block;
        width: 50%;
        height: auto;
    }
    &-content{
        display:flex;
        flex-direction: column;
        justify-content: flex-end;
        align-content: flex-end;
        margin-right: 5%;
        margin-left: 5%;
        flex: 1 1;
        text-align: right;
        hr{
            text-align: right;
            display: block;
            height: 1px;
            border: 0;
            width: calc(100% - 156px);
            border-top: 1px solid rgba(0,0,0,0.25);
            margin-right: 0;
        }
        p{
            margin: 1.5rem 0;
        }
        .btn{
            padding: 16px;
            min-width: 180px;
        }
    }
    .content{
        max-width: 440px;
        align-self: flex-end;
        a{
            display: inline-block;
            margin: 0 auto;
            text-align: center;
        }
    }
}

.page-title{
    text-transform: uppercase;
}

.sales{
    margin: 6rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .title{
        h6{
            max-width: 400px;
        }
    }
    img{
        display:block;
        width: 50%;
        height: auto;
    }
    &-content{
        display:flex;
        flex-direction: column;
        justify-content: flex-start;
        align-content: flex-start;
        margin-right: 5%;
        margin-left: 5%;
        flex: 1 1;
        text-align: left;
        hr{
            text-align: right;
            display: block;
            height: 1px;
            border: 0;
            width: calc(100% - 156px);
            border-top: 1px solid rgba(0,0,0,0.25);
            margin-left: 0;
        }
    }
    .content{
        .price{
            color: #26407c;
        }
        h5{
            font-family: 'Manrope', sans-serif;
            font-size: 15px;
            text-transform: uppercase;
            font-weight: 600;
            letter-spacing: 3px;
            margin: 10px 0;
        }
        p{
            margin: 5px 0;
        }
    }
}

@media (max-width: $mobile) { 
    .biography{
        flex-direction: column;
        padding: 4rem 0;
        img{
            width: 100%;
            padding: 0;
        }
        .biography-content{
            padding: 2rem;
            text-align: center;
            hr{
                text-align: center;
                margin: 0 auto;
            }
        }
        .content{
            display: flex;
            flex-direction: column;
            a{
                margin: 0 auto;
                align-self: center;
            }
        }
    }
    .sales{
        flex-direction: column;
        margin: 4rem auto;
        img{
            width: 100%;
        }
        &-content{
            justify-content: center;
            align-content: center;
            margin: 0 auto;
            text-align: center;
            hr{
                text-align: center;
                margin: 0 auto;
            }
            .title{
                margin: 2rem 1rem;
            }
        }
    }
}
</style>
